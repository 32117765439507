import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { offerColorMap } from "utils/app.util";
import "./PackCategoryStep.css";

const PackCategoryStep = ({ offerTypes, selectedOfferType, formik }) => {
    const { t } = useTranslation();
    const offerNameKey = {
        "Subscription": 82,
        "One Time Purchase": 83
    }

    return (
        <>
            <div className="login-thumbnail2">
                <img src="/image/login_img.png" alt="login" />
            </div>
            <Form onSubmit={formik.handleSubmit}>
                <div className="offer-options">
                    <p className="label">{t('98')}</p>
                    {offerTypes.map(offer => (
                        <div key={offer} className="offer" style={selectedOfferType === offer ? {
                            border: `1px solid rgb(${offerColorMap.get(offer)})`,
                            "--offer-color": `${offerColorMap.get(offer)}`
                        } : {}} onClick={() => formik.setFieldValue("offerType", offer)}>
                            <Form.Check.Label htmlFor={offer}>{t(offerNameKey[offer])}</Form.Check.Label>
                            <Form.Check.Input
                                id={offer}
                                name="offerType"
                                type="radio"
                                checked={selectedOfferType === offer}
                                onChange={formik.handleChange}
                                value={offer}
                            />
                        </div>
                    ))}
                </div>
                <button className="btn btn-primary btn-pm-custom" type="submit" disabled={formik.isSubmitting}>
                    {t('78')}
                </button>
                {/* <div className="disclaimer">
                    <p className="mb-2 fw-medium">Disclaimer:</p>
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum.</p>
                </div> */}
            </Form>
        </>
    )
}

export default PackCategoryStep;
