import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getTopCompetition } from '../services/home';
import { useTranslation } from 'react-i18next';


const ChampionshipDetail = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [listTopCompetition, setListTopCompetition] = useState([]);
    const [yourRank, setYourRank] = useState([]);
    const [getRecord, setGetRecord] = useState([]);
    useEffect(() => {
        getTopCompetition(id).then((res) => {
            setListTopCompetition(res.data)
            setYourRank(res.participant)
        }).catch((err) => {
            console.log(err)
        })
    }, [getRecord]);


    const convertSecondsToMinutesAndSeconds = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
    return (
        <div className='home'>
            <div className="main">
                <div className="container box-wrapper">
                    <div className="header">
                        <div className="row align-items-center">
                            <div className="col-4 text-start">
                                <div className="logo d-flex">
                                    <a href="#">
                                        <img src="/image/Logo_new.png" alt="Logo" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-8 text-end btnSearchBox">

                            </div>
                        </div>
                    </div>

                    {listTopCompetition ? (
                        <>
                            <div className="page-title ">
                                <div className="page-title-circle">
                                    <img src="/image/more.png" alt="Logo" />
                                </div>
                                <span>{t('76')}</span>
                            </div>
                            {yourRank && yourRank.length > 0 ? (
                                <div className='your-rank d-flex justify-content-between'>
                                    <div className='rank-of-user'>
                                        <b>Your Rank #{yourRank[0].participantRank} </b>
                                    </div>
                                    <div className="leaderboard-score w-33 text-right duration-Time">
                                        <span>
                                            <img src="/image/chronometer.png" alt="Tag" />
                                            {convertSecondsToMinutesAndSeconds(yourRank[0].durationTime)}

                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            <div className="card-simple-outer">
                                <ul className="leaderboard-list-wrapper leaderboard-list-won">
                                    <li className="leaderboard-list">
                                        <a href="#" className="d-flex justify-content-between w-100">

                                            <div className='w-33'>

                                                <b className='user-name-comp'>Name</b>
                                            </div>
                                            <p className='w-33'><b>{t('6')}</b>  </p>
                                            <div className="leaderboard-score w-33 text-right">
                                                <b>Time</b>



                                            </div>
                                        </a>
                                    </li>
                                    {
                                        listTopCompetition.map((listing, index) => {
                                            return <li className="leaderboard-list">
                                                <a href="#" className="d-flex justify-content-between w-100">

                                                    <div className='w-33'>
                                                        <p className='user-name-comp'>{listing.participant?.name || 'Guest'}</p>

                                                    </div>
                                                    <p className='w-33'> {listing.rightScore}  </p>
                                                    <div className="leaderboard-score w-33 text-right">
                                                        <span>
                                                            <img src="/image/chronometer.png" alt="Tag" />
                                                            {convertSecondsToMinutesAndSeconds(listing.durationTime)}

                                                        </span>
                                                    </div>
                                                </a>
                                            </li>
                                        })
                                    }
                                    {!listTopCompetition.length && (<li className="leaderboard-list">
                                        <p>{t('97')}</p>
                                    </li>)
                                    }
                                </ul>
                            </div>
                        </>

                    ) : (
                        <div className='featured-quiz pb-2 text-center custom-campionship'>
                            <img src="/image/soon.png" alt="Logo" className='rocket' />
                            <h1 className='ft-65'>{t('67')}</h1>
                            <p className='cg-wine mt-4'>{t('68')}</p>
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}

export default ChampionshipDetail