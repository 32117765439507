import React, { useState, useEffect } from 'react'
import './css/screen3.css';
import { useNavigate } from 'react-router-dom';
import { getProfile, updateProfile } from '../services/home';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { getProfileImage } from 'utils/app.util';

const EditProfile = () => {
    const [profile, setProfile] = useState([]);
    const [state, setState] = useState({
        name: "",
        image: "",
    });
    const fetchData = async () => {
        try {
            const res = await getProfile();
            //console.log(res); 
            setProfile(res);
            setState(prevState => ({
                ...prevState,
                name: res.name,
            }))
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setState(prevState => ({ ...prevState, image: reader.result }));
                profile.image = reader.result
            };

            reader.readAsDataURL(file);
        }
    };
    const handleFileRemove = () => {
        setState(prevState => ({ ...prevState, image: '' }));
        profile.image = ''
    }
    const onSubmit = async (event) => {
        event.preventDefault();
        await updateProfile({
            ...state
        }).then(res => {
            toast("Profile update has been completed successfully.")
            fetchData();
        }).catch(err => {
            toast("An error occurred; please try again later.")
            console.log(err)
        })

    }

    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <><ToastContainer /><div className='home'>
            <div className="main">
                <div className="container-fluid box-wrapper difficult">
                    <div className="page-header pb-5">
                        <div className="box-wrapper mx-auto text-center position-relative header-container">
                            <button type="button" className="icon-button" onClick={() => navigate(-1)}>
                                <img src="/image/back.png" className='arrow' alt="back" />
                            </button>
                            <span className="levels-title">{t('10')}</span>
                        </div>
                    </div>
                    <div className="edit-profile">
                        <div className='pb-2'>
                            <div className="row gx-0  d-flex">
                                <div className="col-3 text-start">
                                    <div className="d-flex align-items-baseline">
                                        <div className="user-avatar">
                                            <img src={getProfileImage(profile?.image)} alt="avatar" className="profile" />
                                            <span className="delete-avatar"><img src="/image/delete.png" alt="delete avatar" className="delete" onClick={handleFileRemove} /></span>
                                        </div>

                                    </div>

                                </div>

                                <div className="col-9 text-start avatar-title">
                                    <div className="flex-grow-1 ms-2">
                                        <div className="edit-profile-name">Avatar</div>
                                        <div className="choose-file-wrapper">
                                            <button className="choose-file"><img src="/image/cloud_upload.png" alt="upload" className="cloud_upload" /> {t('40')}</button>
                                            <input type="file" className="myChoosefile" name="file" onChange={handleFileChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 text-start profile-setting">
                                <hr />
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">{t('41')}</label>
                                    <input type="email" className="form-control" placeholder="Enter Name" value={state.name} onChange={(event) => setState({ ...state, name: event.target.value })} />
                                </div>

                                <button type="submit" className="btn btn-primary" onClick={onSubmit}>{t('42')}</button>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div></>
    )
}

export default EditProfile