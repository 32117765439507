import { Form, InputGroup, Button } from "react-bootstrap";
import { isNotNumericKey } from "utils/input.util";
import { useTranslation } from "react-i18next";

import "./PhoneInputStep.css";

const PhoneInputStep = ({ formik, defaultCountry, isISE2Flow }) => {
    const { t } = useTranslation();

    function getSelectedDropdownItem(country) {
        return (
            <>
                <img
                    src={`https://flagcdn.com/${country.isoCode}.svg`}
                    width="24"
                    alt={country.name} />
                <span>
                    {country.dialCode}
                </span>
            </>
        )
    }

    return (
        <>
            <div className="login-thumbnail2">
                <img src="/image/login_img.png" alt="login" />
            </div>
            <div className="phone-input">
                <h3 className="login-title my-3 text-center">{t('51')}</h3>
                <Form onSubmit={formik.handleSubmit}>
                    <Form.Group>
                        {!isISE2Flow && (<InputGroup className="has-validation">
                            <div className="dialing-code">
                                {getSelectedDropdownItem(defaultCountry)}
                            </div>
                            <Form.Control
                                id="mobileNumber"
                                name="mobileNumber"
                                type="text"
                                placeholder={t('81')}
                                onKeyDown={e => isNotNumericKey(e.key) && e.preventDefault()}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.mobileNumber}
                                isInvalid={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {t(formik.errors.mobileNumber)}
                            </Form.Control.Feedback>
                        </InputGroup>)}
                        <Button type="submit" variant="primary" className="w-100" disabled={!formik.isValid || formik.isSubmitting}>
                            {t('80')}
                        </Button>
                    </Form.Group>
                </Form>
            </div>
        </>
    );
}

export default PhoneInputStep;
