import { React, useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom';
import { getAllQuiz } from '../services/home';
import { useTranslation } from 'react-i18next';

const AllQuizes = () => {
    const [allQuiz, setAllQuiz] = useState([]);
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const { t } = useTranslation();

    const handleSearchClick = () => {
        // Toggle the state to expand/collapse the search input box
        setIsSearchExpanded((prevIsSearchExpanded) => !prevIsSearchExpanded);
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    // Filter quizzes based on the search term
    const filteredQuizzes = allQuiz.filter((quiz) =>
        quiz.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        getAllQuiz(localStorage.getItem('selectedLanguage')).then((res) => {
            console.log(res)
            setAllQuiz(res.data)

        }).catch((err) => {
            console.log(err)
        })
    }, [])

    return (
        <div className='home'>
            <div className="main">
                <div className="container box-wrapper">
                    <div className="header">
                        <div className="row align-items-center">
                            <div className="col-4 text-start">
                                <div className="logo d-flex">
                                    <Link href="/">
                                        <img src="/image/Logo_new.png" alt="Logo" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-8 text-end btnSearchBox">
                                <div className="search" id="btnSearch">
                                    <img src="/image/search.png" alt="Logo" onClick={handleSearchClick} />
                                </div>
                                {isSearchExpanded && (
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        className="search-input"
                                        onChange={handleSearchChange}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="page-title">
                        <div className="page-title-circle">
                            {/* <span className="qf-icon-list"></span> */}
                            <img src="/image/more.png" alt="Logo" />
                        </div>
                        <span>{t('4')}</span>
                    </div>
                    <div className="card-simple-outer">
                        <ul className="quiz-list-wrapper">
                            {
                                filteredQuizzes && filteredQuizzes.map((quiz) => {
                                    return <li className="quiz-list">
                                        <NavLink to='/levels' className="d-flex w-100" state={quiz}>
                                            <div className="quiz-list-pict">
                                                <img src={quiz?.image} alt="Quiz" />
                                            </div>
                                            <div className="quiz-list-info">
                                                <h4 className="quiz-list-info-title">{quiz?.name}</h4>
                                                <p>{quiz?.description}</p>
                                            </div>
                                        </NavLink>
                                    </li>
                                })
                            }
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllQuizes