import React from 'react'
import './css/screen3.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Screen3 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='home'>
      <div className="main">
        <div className="container-fluid box-wrapper difficult">
          <div className="page-header pb-5">
            <div className="box-wrapper mx-auto text-center position-relative header-container">
              <button type="button" className="icon-button" onClick={() => navigate(-1)}>
                <img src="/image/back.png" className='arrow' alt="back" />
              </button>
              <span className="levels-title">{t('13')}</span>
            </div>
          </div>
          {/* <div className="home-profile contact-us">
            <h3 className="choose-title mb-3">{t('13')}:</h3>
            <p>{t('49')}.</p>

            <a href="tel:+919999999999" className="info"> <img src="/image/call.png" alt="phone" /> (123) 456 7896</a>
            <a href="mailto:helpdesk@rainbowwsoft.com" className="info"><img src="/image/mail.png" alt="email" /> helpdesk@rainbowwsoft.com</a>

          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Screen3