import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import OTPInput from "react-otp-input";
import "./OTPInputStep.css";


const OTPInputStep = ({ formik, isISE2Flow }) => {
    const { t } = useTranslation();
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        const isOTPValid = isISE2Flow ? true : formik.values.otp?.length === 4;
        setIsFormValid(isOTPValid);
    }, [formik.values.otp, isISE2Flow]);

    return (
        <>
            <div className="login-thumbnail2">
                <img src="/image/login_img.png" alt="login" />
            </div>
            <div className="otp-input">
                <h3 className="login-title my-3 text-center">{t('51')}</h3>
                <Form onSubmit={formik.handleSubmit}>
                    {!isISE2Flow && (<div className="otp_section mb-4">
                        <p className="label">{t('53').toUpperCase()}</p>
                        <OTPInput
                            value={formik.values.otp}
                            numInputs={4}
                            renderInput={(props, index) => (
                                <input
                                    {...props}
                                    type="tel"
                                    inputMode="numeric"
                                />
                            )}
                            inputStyle="form-control form-otp"
                            onChange={(otp) => formik.setFieldValue("otp", otp)}
                        />
                    </div>)}
                    <button className="btn btn-primary btn-pm-custom" type="submit" disabled={!isFormValid || formik.isSubmitting}>
                        {t('80')}
                    </button>
                </Form>
            </div>
        </>
    )
}

export default OTPInputStep;
